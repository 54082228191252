var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.tag, _vm._g({
    tag: "component"
  }, _vm.$listeners), [_c('b-avatar', {
    staticClass: "badge-mininal",
    attrs: {
      "size": "50",
      "src": _vm.user.image,
      "variant": "white"
    }
  }), _c('div', {
    staticClass: "chat-info flex-grow-1 d-flex-center justify-content-start"
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v(" " + _vm._s(_vm.user.label) + " ")])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }